
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
/**
 * @module Home
 * @description Home Page
 */
import { lazy, Suspense, useContext } from 'react';
import Head from 'next/head';
import Template from '@/templates/Default';
import ReturnVisitorModal from '@/blocks/Modals/ReturnVisitorModal';
import ResumeQuoteModal from '@/blocks/Modals/ResumeQuoteModal';

// Temporary Preamp Assets Moved to Base Site
import Hero5G from '@/blocks/Heroes/AddressCheck5G/HeroAddressCheck5G';
import SplitSectionYTTV from '@/blocks/SplitSectionYTTV';
const CarouselP72 = lazy( () => import( '@/blocks/Carousel P72 Lite' ) );
import FloatingPanel from '@/blocks/FloatingPanel';
const ColumnContentP72 = lazy( () => import( '@/blocks/ColumnContent/ColumnContentP72' ) );
import { FuelContext } from '@/providers/fuel-provider';

// Cohesion
import MonarchFallback from '@/blocks/MonarchFallback';
import { MonarchSlot } from '@redventures/cohesion-utils-react';

/**
 * @function Page
 * @description Page
 */
const Page = () => {
  const { resumeQuote, phoneHeavy, quoteCreatedAfterCutoffDate } = useContext( FuelContext );

  return (
    <>
      <Head>
        <title>Fiber Internet, TV & Phone Service Provider | Frontier Internet</title>
        <meta
          name="description"
          content="Get high-speed internet with Frontier! Explore fiber optic internet plans & bundle with TV & phone for incredible value. Ultrafast fiber internet. Shop Now!"
        />
        <link rel="canonical" href="https://frontier.com/" />
      </Head>

      <Template
        title="Frontier Fiber Internet Service Provider"
        modal={
          ! quoteCreatedAfterCutoffDate && resumeQuote && ! phoneHeavy ? (
            <MonarchSlot slotId="frdcHomeModalResumeQuote">
              <ResumeQuoteModal />
            </MonarchSlot>
          ) : (
            <MonarchSlot slotId="frdcHomeModal">
              <ReturnVisitorModal />
            </MonarchSlot>
          )
        }
        hero={
          <>
            {/* Home | Banner */}
            <MonarchSlot slotId="frdcHomeBanner"/>

            {/* Home | Hero */}
            <MonarchSlot
              slotId="frdcHomeHero"
              fallback={
                <MonarchFallback height={ [ '533px', '323px', '431px' ] } />
              }
            >
              <Hero5G />
            </MonarchSlot>
          </>
        }
        primary={
          <>
            {/* Home | Section 1 */}
            <MonarchSlot
              slotId="frdcHomeSection1"
              fallback={
                <MonarchFallback height={ [ '309px', '309px', '323px' ] } />
              }
            >
              <SplitSectionYTTV />
            </MonarchSlot>

            {/* Home | Section 2 */}
            <MonarchSlot
              slotId="frdcHomeSection2"
              fallback={
                <MonarchFallback height={ [ '738px', '600px', '620px' ] } />
              }
            >
              <Suspense>
                <CarouselP72 />
              </Suspense>
            </MonarchSlot>

            {/* Home | Section 3 */}
            <MonarchSlot
              slotId="frdcHomeSection3"
              fallback={
                <MonarchFallback height={ [ '738px', '420px', '420px' ] } />
              }
            >
              <FloatingPanel />
            </MonarchSlot>

            {/* Home | Section 4 */}
            <MonarchSlot
              slotId="frdcHomeSection4"
              fallback={
                <MonarchFallback height={ [ '738px', '596px', '527px' ] } />
              }
            >
              <Suspense>
                <ColumnContentP72 />
              </Suspense>
            </MonarchSlot>

            {/* Home | Section 5 */}
            <MonarchSlot
              slotId="frdcHomeSection5"
              fallback={ <MonarchFallback height={ [ '0px', '0px', '0px' ] } /> }
            />

            {/* Home | Section 6 */}
            <MonarchSlot slotId="frdcHomeSection6"/>

            {/* Home | Section 7 */}
            <MonarchSlot slotId="frdcHomeSection7"/>

            {/* Home | Section 8 */}
            <MonarchSlot slotId="frdcHomeSection8"/>

            {/* Home | Sticky CTA */}
            <MonarchSlot slotId="frdcHomeStickyCta"/>
          </>
        }
      ></Template>
    </>
  );
};

export default Page;
